import React, { useContext, useMemo, useRef, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useLocation, Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import OrganizationLogo from 'components/organization_logo'
import Navigation from 'components/navigation'
import NavigationItem from 'components/navigation_item'
import Icon from 'components/icon'
import Avatar from 'components/avatar'
import Search from 'components/search'
import ModalContext from 'components/modal/context'
import Menu, { MenuItem } from 'components/menu'
import SearchActiveContext from 'components/search/context'
import useSearchBarSize from 'components/header/hooks/use_search_bar_size'
import Button from 'components/button'
import {
  StyledHeader,
  StyledWrapper,
  StyledRightBlock,
  StyledSearchWrapper,
  StyledButtonNetwork,
  StyledButtonProfile,
  StyledLeftBlock,
  StyledNavigation,
  StyledButtonBurger,
  StyledButtonBurgerOpen,
  StyledButtonBurgerClose,
} from 'components/header/style'
import ModalSearch from 'components/modal_search'

import useUserCan from 'views/iam/hooks/use_user_can'
import useCurrentUser from 'views/iam/hooks/use_current_user'
import { signOut } from 'views/iam/slices/iamSlice'
import useBookingRole from 'views/booking/hooks/use_booking_role'
import useOrganizationCan from 'views/iam/hooks/use_organization_can'

import {
  NETWORK_COMPANIES_READ,
  ANALYTICS_ACCESS,
  USER_ORGANIZATION_READ,
  TRUSTED_ROUTES_ACCESS,
} from 'constants/permissions'
import { WITH_EMBED_ACCESS_TO_SHIPMENT_INDEX } from 'constants/organization_features'

import useBreakpoints from 'services/hooks/use_breakpoints'
import useTracker from 'services/analytics/hooks/use_tracker'
import {
  routeAnalytics,
  routeBooking,
  routeBookings,
  routeDashboard,
  routeShipments,
  routeShipment,
  routeUsers,
  routeTrustedRoutes,
  routeAtlas,
} from 'services/helpers/routes'

import { TEST_ID_HEADER_NETWORK_BUTTON, TEST_ID_HEADER_PROFILE_BUTTON } from 'tests/e2e/test_ids'
import AppContext from 'app/contexts/app_context'
import ModalSettings from 'components/modal_settings'
import NavigationDropdownItem, {
  NavigationDropdownOption,
} from 'components/navigation_dropdown_item'
import { Category } from 'views/analytics/types'
import useCanAccessAtlas from 'views/atlas/hooks/use_can_access_atlas'
import { Category as AtlasCategory } from 'views/atlas/types/category'
import { setLogoutInitiatedByUser } from 'views/iam/slices/ssoSlice'
import { ANALYTICS_MENU, analyticsAnchorPerCategory } from 'constants/product_fruit_anchors'

const ANALYTICS_ORDER = {
  [Category.VOLUMES]: 0,
  [Category.PERFORMANCE]: 1,
  [Category.DETENTION_DEMURRAGE]: 2,
  [Category.EMISSIONS]: 3,
  [Category.BOOKING]: 4,
  [Category.DATA_QUALITY]: 5,
  [Category.PRIMARY]: 6,
  [Category.CUSTOMIZED]: 7,
  [Category.BENCHMARK]: 8,
  [Category.MARKET_INTEL]: 9,
}

const Header = React.memo(({ variant }) => {
  const { isMobile, isTablet, isDesktop, isDesktopLarge } = useBreakpoints()
  const dispatch = useDispatch()
  const searchRef = useRef(null)
  const [searchValue, setSearchValue] = useState(null)
  const [searchOpen, setSearchOpen] = useState(false)

  const {
    setOpen,
    closeAll: closeAllModals,
    anyOpen: anyOpenModal,
    modals,
  } = useContext(ModalContext)

  const isSearchCompact = isTablet || isDesktop || isDesktopLarge

  const leftBlockRef = useRef(null)
  const rightBlockRef = useRef(null)
  const wrapperRef = useRef(null)
  const searchWrapperRef = useRef(null)
  useSearchBarSize({
    searchRef,
    leftBlockRef,
    rightBlockRef,
    wrapperRef,
    searchWrapperRef,
    searchOpen,
  })

  const [navOpen, setNavOpen] = useState(false)

  const variantReverse = useMemo(() => (variant === 'light' ? 'dark' : 'light'), [variant])

  const { t } = useTranslation()
  const currentUser = useCurrentUser()
  const { features } = useOrganizationCan()
  const location = useLocation()
  const can = useUserCan()
  const { hasAnyBookingRole } = useBookingRole()
  const { track } = useTracker()
  const { isShared, isEmbedded } = useContext(AppContext)
  const onHeaderClick = useCallback(() => {
    if (anyOpenModal) {
      closeAllModals()
    }
  }, [closeAllModals, anyOpenModal])
  const contextValue = useMemo(
    () => ({ searchValue, setSearchValue }),
    [searchValue, setSearchValue]
  )

  const canAccessShipments =
    isShared || !isEmbedded || (isEmbedded && features(WITH_EMBED_ACCESS_TO_SHIPMENT_INDEX))
  const canAccessAtlas = useCanAccessAtlas()

  const withUserDropdown = currentUser.organization.displaySettings.topMenuUserDropdown

  return (
    <SearchActiveContext.Provider value={contextValue}>
      <ModalSettings modalName='settings' key='current-user-settings' />
      <StyledHeader $variant={variant}>
        <StyledWrapper ref={wrapperRef} onClick={onHeaderClick}>
          <StyledLeftBlock as={Link} to={routeDashboard()} ref={leftBlockRef}>
            <OrganizationLogo variant={variantReverse} condensed={isMobile} />
            <StyledButtonBurger $navOpen={navOpen} onClick={() => setNavOpen(!navOpen)}>
              <StyledButtonBurgerOpen as={Icon} name='menu' />
              <StyledButtonBurgerClose as={Icon} name='close' />
            </StyledButtonBurger>
          </StyledLeftBlock>
          <StyledNavigation
            as={Navigation}
            $hidden={isSearchCompact && searchOpen}
            isOpenMobile={navOpen}
          >
            <NavigationItem
              active={location.pathname === routeDashboard()}
              text={t('dashboard.title')}
              to={routeDashboard()}
              variant={variantReverse}
            />
            {canAccessShipments && (
              <NavigationItem
                active={[routeShipments(), routeShipment('')].some((path) =>
                  location.pathname.includes(path)
                )}
                text={t('shipments.title')}
                to={routeShipments()}
                variant={variantReverse}
              />
            )}
            {can(ANALYTICS_ACCESS) && currentUser.availableAnalyticsCategories.length <= 1 && (
              <NavigationItem
                active={[routeAnalytics(), routeAnalytics('')].some((path) =>
                  location.pathname.includes(path)
                )}
                text={t('analytics.title')}
                to={routeAnalytics(currentUser.availableAnalyticsCategories[0] || Category.PRIMARY)}
                variant={variantReverse}
              />
            )}
            {can(ANALYTICS_ACCESS) && currentUser.availableAnalyticsCategories.length > 1 && (
              <NavigationDropdownItem
                dataComponent={ANALYTICS_MENU}
                active={[routeAnalytics(), routeAnalytics('')].some((path) =>
                  location.pathname.includes(path)
                )}
                text={t('analytics.title')}
                variant={variantReverse}
                options={[...currentUser.availableAnalyticsCategories]
                  .sort((a, b) => ANALYTICS_ORDER[a] - ANALYTICS_ORDER[b])
                  .map((category) => (
                    <NavigationDropdownOption
                      dataComponent={analyticsAnchorPerCategory(category)}
                      active={location.pathname === routeAnalytics(category)}
                      text={t(`analytics.categories.${category}`)}
                      to={routeAnalytics(category)}
                    />
                  ))}
              />
            )}
            {can(TRUSTED_ROUTES_ACCESS) && (
              <NavigationItem
                active={location.pathname === routeTrustedRoutes()}
                text={t('trustedRoutes.title')}
                to={routeTrustedRoutes()}
                variant={variantReverse}
              />
            )}
            {canAccessAtlas && (
              <NavigationDropdownItem
                active={[routeAtlas(), routeAtlas('')].some((path) =>
                  location.pathname.includes(path)
                )}
                text={t('atlas.title')}
                variant={variantReverse}
                options={[AtlasCategory.CLUSTER, AtlasCategory.HUB, AtlasCategory.ADDRESS].map(
                  (category) => (
                    <NavigationDropdownOption
                      active={location.pathname === routeAtlas(category)}
                      text={t(`atlas.categories.${category}`)}
                      to={routeAtlas(category)}
                    />
                  )
                )}
              />
            )}
            {hasAnyBookingRole && (
              <NavigationItem
                active={[routeBookings(), routeBooking('')].some((path) =>
                  location.pathname.includes(path)
                )}
                text={t('bookings.title')}
                to={routeBookings()}
                variant={variantReverse}
              />
            )}
            {can(USER_ORGANIZATION_READ) && (
              <NavigationItem
                active={location.pathname === routeUsers()}
                text={t('users.title')}
                to={routeUsers()}
                variant={variantReverse}
              />
            )}
          </StyledNavigation>
          <StyledRightBlock ref={rightBlockRef}>
            <StyledSearchWrapper ref={searchWrapperRef}>
              <Search
                ref={searchRef}
                text={
                  isMobile
                    ? t('actions.search')
                    : `${t('actions.search')} ${t('shipments.shipment', { count: 1 })}`
                }
                variant={variantReverse}
                compact={isSearchCompact}
                onToggleOpen={(value) => {
                  if (value === false) {
                    setSearchValue(null)
                  } else {
                    track('Search')
                  }
                  setSearchOpen(value)
                }}
                highlighted={isMobile}
              />
            </StyledSearchWrapper>
            {can(NETWORK_COMPANIES_READ) && (
              <StyledButtonNetwork
                as={Button}
                testId={TEST_ID_HEADER_NETWORK_BUTTON}
                variant='icon'
                icon='network'
                onClick={() => setOpen('network', true)}
              />
            )}
            <StyledButtonProfile>
              <Menu
                disabled={!withUserDropdown}
                control={
                  <Avatar
                    hover={withUserDropdown}
                    firstName={currentUser.profile.firstName}
                    lastName={currentUser.profile.lastName}
                    color={currentUser.profile.color}
                    testId={TEST_ID_HEADER_PROFILE_BUTTON}
                  />
                }
                dropdownPosition='right'
              >
                <MenuItem
                  text={t('settings.title')}
                  testId='header-settings-menu'
                  onClick={() => {
                    setOpen('settings', true)
                    track('User / Settings')
                  }}
                />
                <MenuItem
                  text={t('profile.title')}
                  onClick={() => {
                    setOpen('profile', true)
                    track('User / Profile')
                  }}
                />
                <MenuItem
                  text={t('faq.title')}
                  to='https://help.wakeo.co/'
                  onClick={() => {
                    track('FAQ')
                  }}
                />
                <MenuItem
                  text={t('actions.signOut')}
                  onClick={() => {
                    dispatch(setLogoutInitiatedByUser(true))
                    dispatch(signOut())
                    track('Sign out')
                  }}
                />
              </Menu>
            </StyledButtonProfile>
          </StyledRightBlock>
        </StyledWrapper>
        {modals.search && <ModalSearch />}
      </StyledHeader>
    </SearchActiveContext.Provider>
  )
})

Header.propTypes = {
  /* variant */
  variant: PropTypes.oneOf(['light', 'dark']),
}

Header.defaultProps = {
  variant: 'light',
}

export default Header
