import React, { useContext, useEffect, useState } from 'react'

import { Route, Switch, Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ProductFruits } from 'react-product-fruits'

import {
  ANALYTICS_ACCESS,
  USER_ORGANIZATION_READ,
  AUDIT_TRAIL_READ,
  TRUSTED_ROUTES_ACCESS,
} from 'constants/permissions'
import { WITH_EMBED_ACCESS_TO_SHIPMENT_INDEX } from 'constants/organization_features'

import Analytics from 'views/analytics'
import Dashboard from 'views/dashboard'
import SignIn from 'views/iam/sign_in'
import PasswordRecovery from 'views/iam/password_recovery'
import Shipment from 'views/shipment'
import Shipments from 'views/shipments'
import useCurrentUser from 'views/iam/hooks/use_current_user'
import useUserCan from 'views/iam/hooks/use_user_can'
import useOrganizationCan from 'views/iam/hooks/use_organization_can'
import BookingsPage from 'views/bookings'
import BookingDetailsPage from 'views/booking'
import BookingCreate from 'views/booking/components/create'
import useBookingRole from 'views/booking/hooks/use_booking_role'
import BookingEdit from 'views/booking/components/edit'
import { refreshAccessToken, ssoSignIn } from 'views/iam/slices/iamSlice'
import Users from 'views/users'
import { addNotification } from 'views/notifications/slice'
import TrustedRoutes from 'views/trusted_routes'
import Atlas from 'views/atlas'

import useOnce from 'services/hooks/use_once'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import { isProd } from 'services/helpers/environment'

import Page from 'components/page'
import NotifMessage from 'components/notif_message'
import {
  routeDashboard,
  routePasswordRecovery,
  routeSignIn,
  routeSSO,
  routeShipments,
  routeShipment,
  routeShipmentAuditTrail,
  routeAnalytics,
  routeBookings,
  routeBooking,
  routeBookingCreate,
  routeBookingEdit,
  routeUsers,
  routeTrustedRoutes,
  routeInvitation,
  routeAtlas,
} from 'services/helpers/routes'
import { isPresent } from 'services/helpers/values'

import AppContext from 'app/contexts/app_context'
import ShipmentAuditTrail from 'views/audit_trail'
import { Category } from 'views/analytics/types'
import LoadingView from 'views/loading'
import { TEST_ID_ROOT_LOADER_WAKEO } from 'tests/e2e/test_ids'
import useCanAccessAtlas from 'views/atlas/hooks/use_can_access_atlas'

import { isSSOUser } from 'views/iam/slices/ssoSlice'

import SSORedirect from 'views/iam/ssoRedirect'
import SSOSignOut from 'views/iam/ssoSIgnOut'

const StandaloneApp = () => {
  const user = useCurrentUser()
  const { t, i18n } = useTranslation()
  const userCan = useUserCan()
  const canAccessAtlas = useCanAccessAtlas()
  const dispatch = useAppDispatch()
  const { hasAnyBookingRole, isShipper } = useBookingRole()
  const fromSSO = window.location.href.indexOf('/sso?') > -1
  const { features } = useOrganizationCan()
  const { isEmbedded } = useContext(AppContext)
  const [applicationReady, setApplicationReady] = useState(false)
  const isAnSSOUser = useSelector(isSSOUser)

  const canAccessShipments =
    !isEmbedded || (isEmbedded && features(WITH_EMBED_ACCESS_TO_SHIPMENT_INDEX))
  useEffect(() => {
    if (user.isSignedIn) {
      i18n.changeLanguage(user.profile.settings.locale)
    }
  }, [user, i18n])

  const handleSSOlocation = () => {
    if (fromSSO) {
      const params = new URLSearchParams(window.location.search)
      const ssoToken = params.get('sso_token')
      const redirectUrl = params.get('redirect')

      if (ssoToken) {
        dispatch(ssoSignIn({ ssoToken }))
          .unwrap()
          .then(() => {
            if (isPresent(redirectUrl)) {
              window.location.replace(redirectUrl)
            } else {
              window.location.replace(routeDashboard())
            }
          })
          .catch(() => {
            dispatch(
              addNotification({
                type: 'alert',
                title: t('errors.notification.title'),
                text: t('errors.notification.content'),
              })
            )
          })
      }
    }
  }

  useOnce(() => {
    handleSSOlocation()
  })

  useOnce(() => {
    dispatch(refreshAccessToken()).finally(() => setApplicationReady(true))
  })

  // Before returning the router, we perform the refreshAccessToken request.
  // If the request is successful, the user information is stored in the redux store and the user can access the requested page,
  // otherwise he's redirected to the signIn.
  // We don't use automatic signOut in the case of 498 to prevent users from being disconnected during the useRefreshTokenInterval call.
  return (
    <>
      {applicationReady && !isEmbedded && user.isSignedIn && isProd && (
        <ProductFruits
          workspaceCode={process.env.REACT_APP_PRODUCT_FRUIT_WORKSPACE}
          language={user.profile.settings.locale}
          user={{
            username: user.profile.token,
            email: user.profile.email,
            firstname: user.profile.firstName,
            lastname: user.profile.lastName,
            signUpAt: user.profile.createdAt,
            props: {
              displayMarketIntelTour: user.availableAnalyticsCategories.includes('market_intel'),
            },
          }}
        />
      )}
      {!applicationReady && <LoadingView data-testid={TEST_ID_ROOT_LOADER_WAKEO} />}
      {applicationReady && fromSSO && (
        <Route
          path={routeSSO()}
          render={() => (
            <Page>
              <NotifMessage type='info' title='SSO connexion' text='Waiting for SSO connexion' />
            </Page>
          )}
        />
      )}
      {applicationReady && !fromSSO && !user.isSignedIn && (
        <Switch>
          <Redirect from='/signin/:token' to={routePasswordRecovery(':token')} />
          <Redirect from='/signin' to={routeSignIn()} />
          <Route exact path={routeSignIn()}>
            {isAnSSOUser ? <SSORedirect /> : <SignIn />}
          </Route>
          <Route exact path={routePasswordRecovery(':token?')}>
            <PasswordRecovery />
          </Route>
          <Route exact path={routeInvitation(':invitationToken?')}>
            <PasswordRecovery />
          </Route>
          <Route exact path='/sso/logout'>
            <SSOSignOut />
          </Route>
          <Route>
            <Redirect to={routeSignIn()} />
          </Route>
        </Switch>
      )}
      {applicationReady && !fromSSO && user.isSignedIn && (
        <Switch>
          <Redirect from='/order/:id' to={routeShipment(':id')} />
          <Redirect from='/orders' to={routeShipments()} />

          {/*
            On v2, /settings was a whole page. On v3, it is a modal.
            So we redirect the old page system to a hash system to open modals
           */}
          <Redirect from='/settings' to='#alerts' />
          <Route exact path={routeDashboard()}>
            <Dashboard />
          </Route>
          {userCan(ANALYTICS_ACCESS) && (
            <Route exact path={routeAnalytics(':category')}>
              <Analytics />
            </Route>
          )}
          {userCan(ANALYTICS_ACCESS) && (
            <Redirect from='/analytics' to={routeAnalytics(Category.PRIMARY)} />
          )}
          {userCan(TRUSTED_ROUTES_ACCESS) && (
            <Route exact path={routeTrustedRoutes()}>
              <TrustedRoutes />
            </Route>
          )}
          {canAccessAtlas && (
            <Route exact path={routeAtlas(':category')}>
              <Atlas />
            </Route>
          )}
          {hasAnyBookingRole && (
            <Route exact path={routeBookings()}>
              <BookingsPage />
            </Route>
          )}
          {/*
            Order is important here: /booking/create needs to be resolved _before_ /booking/:id
            If not, /create is interpreted as /:id 
          */}
          {isShipper && (
            <Route exact path={routeBookingCreate()}>
              <BookingCreate />
            </Route>
          )}
          {hasAnyBookingRole && (
            <Route exact path={routeBookingEdit(':id')}>
              <BookingEdit />
            </Route>
          )}
          {hasAnyBookingRole && (
            <Route exact path={routeBooking(':id')}>
              <BookingDetailsPage />
            </Route>
          )}
          {userCan(AUDIT_TRAIL_READ) && (
            <Route exact path={routeShipmentAuditTrail(':id')}>
              <ShipmentAuditTrail />
            </Route>
          )}
          <Route exact path={routeShipment(':id')}>
            <Shipment />
          </Route>
          {canAccessShipments && (
            <Route exact path={routeShipments()}>
              <Shipments />
            </Route>
          )}
          {userCan(USER_ORGANIZATION_READ) && (
            <Route exact path={routeUsers()}>
              <Users />
            </Route>
          )}
          {/* WARN: default route should be the last one, because react-router reads routes from top to bottom. */}
          <Route>
            <Redirect to={routeDashboard()} />
          </Route>
        </Switch>
      )}
    </>
  )
}

export default StandaloneApp
